@import "../../app.scss";

.hero {
  height: calc(100vh - 100px);
  overflow: hidden;
  background: linear-gradient(180deg, #0c0c1d, #111132);
  position: relative;

  .wrapper {
    max-width: 1366px;
    height: 100%;
    margin: auto;

    .textContainer {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;

      @include mobile {
        height: 50%;
        width: 100%;
        gap: 20px;
        align-items: center;
        text-align: center;
      }

      h2 {
        font-size: 30px;
        color: rebeccapurple;
        letter-spacing: 10px;
      }

      h1 {
        font-size: 88px;

        @include mobile {
          font-size: 36px;
        }
      }

      .buttons {
        button {
          padding: 20px;
          border: 1px solid white;
          border-radius: 10px;
          background-color: transparent;
          color: white;
          margin-right: 20px;
          cursor: pointer;
          font-weight: 300;
        }
      }

      img {
        width: 50px;
      }
    }
  }

  .imageContainer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    @include mobile {
      height: 50%;
      width: 100%;
      top: unset;
      bottom: 0;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slidingTextContainer {
    position: absolute;
    font-size: 50vh;
    bottom: -120px;
    white-space: nowrap;
    color: #ffffff09;
    width: 50%;
    font-weight: bold;
  }
}