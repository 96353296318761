@import "../../app.scss";

.portfolio {
  position: relative;

  .progress {
    position: sticky;
    top: 0;
    left: 0;
    padding-top: 50px;
    text-align: center;
    color: orange;
    font-size: 36px;

    @include mobile {
      padding-top: calc(100vh - 100px);
      font-size: 24px;
      display: none;
    }

    .progressBar {
      height: 10px;
      background-color: white;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .wrapper {
      max-width: 1366px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;

      @include mobile {
        flex-direction: column;
      }

      .imageContainer {
        flex: 1;
        height: 50%;

        @include mobile {
          width: 100%;
          max-height: 300px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include mobile {
            object-fit: contain;
          }
        }
      }

      .textContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include mobile {
          transform: none !important;
          padding: 10px;
          align-items: center;
          text-align: center;
        }

        h2 {
          font-size: 72px;

          @include mobile {
            font-size: 36px;
          }
        }

        p {
          color: gray;
          font-size: 20px;

          @include mobile {
            font-size: 16px;
          }
        }

        button {
          background-color: orange;
          border: none;
          border-radius: 10px;
          padding: 10px;
          width: 200px;
          cursor: pointer;
        }
      }
    }
  }
}